import { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useParams } from 'react-router-dom';
import {
  PROPERTY_DEACTIVATED_REASONS,
  ServiceInitialFlowScreen,
} from '@lawnstarter/customer-modules/constants';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  getRecommendations,
  getServiceCtaText,
  getServicesBySections,
  isServiceDisabled,
  sortServiceCards,
} from '@lawnstarter/customer-modules/helpers';
import { useFeatureFlag } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  share_platformsSelector,
  useGetAvailableServicesQuery,
  useGetServicePromosQuery,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType, SharePlatform } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { OfferTile } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { Tooltip } from '@lawnstarter/ls-react-web-common';

import { Loader, RecommendationCarousel } from '@src/components';
import { useRouteNavigation, useSelector } from '@src/hooks';
import { useAskInteractionOnStartup } from '@src/hooks/useAskInteractionOnStartup';

import {
  StyledBrowseContainer,
  StyledBrowseWrapper,
  StyledOfferTileContainer,
  StyledSectionWrapper,
  styles,
} from './styles';

import type { Section } from '@lawnstarter/customer-modules/helpers';
import type { Service } from '@lawnstarter/ls-react-common/types';
import type { CarouselItem } from '@src/components/organisms/RecommendationCarousel/types';

export function BrowseScreen() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(), []);

  const { navigate } = useRouteNavigation();
  const modal = useModal(ModalTemplate.OPTIONAL_ACTION);

  const isRecommendationEnabled = useFeatureFlag(FeatureFlags.ALLOW_RECOMMENDATIONS);

  const { propertyId } = useParams();

  useAskInteractionOnStartup();

  const property = useSelector((state) => state.properties.propertiesById[propertyId as any]);
  const customerId = useSelector((state) => state.auth.user.customer_id!);
  const hasPaymentFailure = useSelector((state) => state.auth.user.customer?.has_payment_failure);
  const sharePlatforms = useSelector(share_platformsSelector(theme));
  const platformLocalShare = useSelector((state) => state.share);
  const firstLogin = useSelector((state) => state.auth.user.firstLogin);

  const { isFetching, data: allServices } = useGetAvailableServicesQuery(
    {
      customerId,
      propertyId: property?.id,
      marketId: property?.market_id,
    },
    { skip: !property },
  );

  // The Nextdoor card is currently hidden to focus on cross-sell
  // See https://lawnstarter.atlassian.net/browse/PE-31429
  const sharedOnNextDoor = useMemo(
    () =>
      true ||
      platformLocalShare.nextdoorLocallyShared ||
      sharePlatforms?.nextdoor?.is_share_complete,
    [platformLocalShare.nextdoorLocallyShared, sharePlatforms?.nextdoor?.is_share_complete],
  );

  const { isFetching: isFetchingPromos, data: servicePromos } = useGetServicePromosQuery(
    {
      customerId,
      propertyId: property?.id,
    },
    { skip: !property },
  );

  const carouselItems = useMemo(
    () =>
      getRecommendations({
        property,
        servicePromos: servicePromos || [],
        sharePlatforms: {
          [SharePlatform.Nextdoor]: sharedOnNextDoor,
        },
        firstLogin,
        fromWeb: true,
      }) as CarouselItem[],
    [property, servicePromos, sharedOnNextDoor, firstLogin],
  );

  const serviceSections = useMemo(() => {
    const sortedServices = sortServiceCards(allServices);

    if (isRecommendationEnabled) {
      return getServicesBySections({ allServices: sortedServices, carouselItems });
    }
    return getServicesBySections({ allServices: sortedServices });
  }, [allServices, carouselItems, isRecommendationEnabled]);

  const showPaymentFailure = useCallback(() => {
    const isPropertyInPaymentFailure =
      property?.can_self_reactivate_reason === PROPERTY_DEACTIVATED_REASONS.PAYMENT_FAILURE;
    const message = isPropertyInPaymentFailure
      ? t('notices.paymentCanceled')
      : t('notices.paymentWillCancel');

    return modal.show({
      icon: 'credit-card-plus',
      action: t('updateYourPayment'),
      buttonText: t('updatePaymentMethod'),
      messages: [message],
      onAction: () => {
        navigate(WebRoutes.updatePayment);
      },
    });
  }, [property, modal, navigate]);

  const carouselItemBeforePress = useCallback(() => {
    if (hasPaymentFailure) {
      showPaymentFailure();
      return false;
    }

    return true;
  }, [hasPaymentFailure, showPaymentFailure]);

  const handlePress = useCallback(
    (service: Service) => {
      if (hasPaymentFailure) {
        return showPaymentFailure();
      }

      if (ServiceInitialFlowScreen[service.str_id]) {
        navigate(ServiceInitialFlowScreen[service.str_id] as WebRoutes, {
          params: { propertyId: property.id },
        });
      } else {
        navigate(WebRoutes.orderService, {
          params: {
            propertyId: property.id,
            serviceId: service.id,
          },
        });
      }
    },
    [navigate, property, hasPaymentFailure, showPaymentFailure],
  );

  const renderServiceCard = useCallback(
    (service: Service) => {
      return (
        <StyledOfferTileContainer key={service.str_id}>
          <OfferTile
            title={service.name}
            image={service.image_url}
            description={service.description}
            onPress={() => handlePress(service)}
            ctaText={getServiceCtaText({ serviceType: service.str_id })}
            isDisabled={isServiceDisabled({ service })}
          />
        </StyledOfferTileContainer>
      );
    },
    [handlePress],
  );

  const renderOtherServiceLink = useCallback(
    (service: Service) => {
      const isDisabled = isServiceDisabled({ service });

      return (
        <View style={style.otherServiceLink} key={service.str_id}>
          <Button
            onPress={() => handlePress(service)}
            disabled={isDisabled}
            trackID="browseOther"
            textColor={theme.colors.blue.primary}
            useLowercase
            labelStyle={style.otherServiceButton}
          >
            {t('browse.informationForAnotherService')}
          </Button>
        </View>
      );
    },
    [handlePress, style, theme.colors.blue.primary],
  );

  const renderCarouselSection = useCallback(() => {
    return (
      <RecommendationCarousel items={carouselItems} itemBeforePress={carouselItemBeforePress} />
    );
  }, [carouselItemBeforePress, carouselItems]);

  const renderSectionItem = useCallback(
    (service: Service) => {
      const renderServiceComponent = () => {
        return service.str_id !== ServiceType.Other
          ? renderServiceCard(service)
          : renderOtherServiceLink(service);
      };

      return renderServiceComponent();
    },
    [renderServiceCard, renderOtherServiceLink],
  );

  const renderSectionTitle = ({ title, tooltip }: Section) => (
    <div className="flex items-center">
      <Text variant="headlineSmall" style={style.sectionTitle}>
        {title}
      </Text>

      {tooltip?.content && (
        <div className="ml-2">
          <Tooltip title={tooltip?.content ?? ''} arrow={true}>
            <div>
              <Icon name="information" size={theme.sizing.s5} color={theme.colors.primary} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );

  if (isFetching || isFetchingPromos) {
    return <Loader testID="browse-spinner" />;
  }

  return (
    <StyledBrowseWrapper>
      {serviceSections.map((section) => {
        return (
          <StyledSectionWrapper key={section.title}>
            {renderSectionTitle(section)}

            <StyledBrowseContainer>
              {section.title === t('recommendedForYou')
                ? renderCarouselSection()
                : section.data.map(renderSectionItem)}
            </StyledBrowseContainer>
          </StyledSectionWrapper>
        );
      })}
    </StyledBrowseWrapper>
  );
}
